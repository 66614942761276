@import url("https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i,900|Montserrat:900");

:root {
  --primary-color: #d23669;
  --primary-color-light: rgb(255, 167, 196);
}

:global * {
  box-sizing: border-box;
}

:global html {
  color: rgba(0, 0, 0, 0.9);
  font: 100%/1.75 Morriweather, Georgia, serif;
}

:global body {
  font-kerning: normal;
  font-family: "Merriweather", "Georgia", serif;
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

:global(pre),
:global(code) {
  font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New",
    monospace;
  font-size: 0.85rem;
  line-height: 1.5;
}
:global pre {
  border-radius: 10px;
  overflow: auto;
  padding: 1em;
}

:global blockquote {
  margin-left: -1.75rem;
  margin-right: 1.75rem;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 1.42188rem;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.75rem;
  font-size: 1.20112rem;
  line-height: 1.75rem;
  color: inherit;
  font-style: italic;
  border-left: 0.32813rem solid hsla(0, 0%, 0%, 0.9);
  border-left-color: inherit;
  opacity: 0.8;
}

:global a {
  color: var(--primary-color);
}
:global a:hover {
  text-decoration: none;
}

:global p,
:global pre,
:global ul,
:global ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}
